<template>
  <article>
    <slices v-if="prismicDoc" :prismic-doc="prismicDoc" />
    <Spinner v-else />
  </article>
</template>

<script>
import Slices from "@/components/slices/homepage/Slices";
import Spinner from "@/components/slices/shared/Spinner";
export default {
  name: "Issue-page",
  components: {Slices, Spinner},
  props: ['issueDocId'],
  data() {
    return {
      prismicDoc: null
    }
  },
  async mounted() {
    // If an issueDocId (The prismic document ID) is specified in the props, load the doc from this
    // Otherwise, use the slug
    if (this.issueDocId) {
      this.prismicDoc = await this.$prismic.client.getByID(this.issueDocId)
    } else if (this.$route.params.issueSlug) {
      this.prismicDoc = await this.$prismic.client.getByUID('issue', this.$route.params.issueSlug)
    }
    // Set issue store
    this.setCurrentIssueStore(this.prismicDoc)
    this.fetchSpotlightNavStatus(this.prismicDoc);
    const pageTitle = this.prismicDoc.data.title ? this.getRichTextAsText(this.prismicDoc.data.title) : ''
    this.addNavBarClass(this.prismicDoc)
    this.trackPageView(pageTitle)
  }
}
</script>

<style scoped>

</style>
