<template>
  <article v-if="prismicDoc" class="pageTheme pageTheme--withbg">
    <Slices v-if="prismicDocs" :prismicDoc="prismicDoc" :prismicDocs="prismicDocs" :categoriesList="regions"
            :selectedCategory="selectedRegion"/>
    <!-- Spinner while loading -->
    <Spinner v-if="prismicDocs.length === 0"/>
  </article>
</template>

<script>
import Slices from "@/components/slices/regions-page/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "regions",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDocs: [],
      prismicDoc: null,
      regions: ["Across the network"],
      selectedRegion: "across the network",
    };
  },
  methods: {
    getContent(regionSlug) {
      this.$prismic.client
          .query(
              this.$prismic.Predicates.any("document.type", [
                "news_item"
              ]),
              {
                pageSize: 1000,
              }
          )
          .then((document) => {

            /** If category slug is passed , we use it , or otherwise its all regions */
            this.selectedRegion = regionSlug ? regionSlug : 'across-the-network';
            this.selectedRegion = this.selectedRegion.charAt(0).toUpperCase() + this.selectedRegion.slice(1)

            /** Build regions and show documents, which are filtered by category*/
            var i;
            for (i = 0; i < document.results.length; i++) {
              let doc = document.results[i];

              if (doc.data.region) {

                /** Add doc category to category list, if category does not exist in the list  */
                if (!this.regions.includes(doc.data.region)) {
                  this.regions.push(doc.data.region);
                }

                /** 'All' means user is on category page without a filter */
                if (this.selectedRegion.toLowerCase() == 'across-the-network') {
                  this.prismicDocs.push(doc);

                  /** If not 'All' filter selected, we filter by passed category */
                } else if (this.selectedRegion.toLowerCase() == doc.data.region.toLowerCase()) {
                  this.prismicDocs.push(doc);
                }
              }
            }
            /** Sort our regions names */
            this.regions.sort();
          });
      this.trackPageView('Regions - ' + this.selectedRegion)
    },
  },
  created() {
    this.getContent(this.$route.params.regionSlug);
  },
  async mounted() {
    this.prismicDoc = await this.$prismic.client.getByUID('page', 'regions'
    );
    this.addNavBarClass(this.prismicDoc)
    this.fetchSpotlightNavStatus(this.prismicDoc);
  },
  beforeRouteUpdate(to, from, next) {
    /** Reset existing variables, lists, array */
    this.prismicDocs = [];
    this.selectedRegion = "";
    this.regions = ["Across the network"];
    //console.log("Here beforeRouteUpdate: " + to.params.regionSlug);
    this.getContent(to.params.regionSlug);
    next();
  },
};
</script>

<style scoped></style>
