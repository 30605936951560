<template>
  <article>
    <!-- Load story-regular-page slices, if story is a regular story -->
    <StorySlices
      v-if="prismicDoc && prismicDoc.type === 'story'"
      :prismic-doc="prismicDoc"
      class="position-relative overflow-hidden"
    />
    <!-- Spinner while loading -->
    <Spinner v-if="!prismicDoc" />
  </article>
</template>

<script>
/** Regular story components */
import StorySlices from "@/components/slices/story/Slices.vue";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "story-page",
  components: {
    StorySlices,
    Spinner,
  },
  methods: {
    async getContent(uid) {
      this.prismicDoc = await this.$prismic.client.getByUID("story", uid);
      this.addNavBarClass(this.prismicDoc)
      this.fetchSpotlightNavStatus(this.prismicDoc);
    }
  },
  created() {
    this.getContent(this.$route.params.storySlug);
  },
  beforeRouteUpdate(to, from, next) {
    this.getContent(to.params.storySlug);
    next();
  },
  data() {
    return {
      prismicDoc: null,
    };
  },
};
</script>

<style scoped></style>
